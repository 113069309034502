<template>
  <div class="puzzles">
	<p>
		In my spare time, I compete in <a href="https://en.wikipedia.org/wiki/Puzzlehunt">puzzlehunts</a>. Puzzlehunts are events in which teams or individuals compete to solve puzzles. These puzzles aren't (necessarily) jigsaw puzzles. They vary wildly in terms of content and structure but are similar to challenges found in escape rooms. I also do a lot of jigsaw puzzles and escape rooms.
	</p>
	<p>
		I've written puzzles for a few hunts, most notably the <a href="https://www.mit.edu/~puzzle/">MIT Mystery Hunt</a>. My team was in charge of running the 2019 hunt, a feat which involved writing upwards of 150 puzzles. I contributed by advising/editing a good chunk of those puzzles, publishing some on the website, and lending my yoga talents to one puzzle in particular. The final website for the hunt is <a href="https://www.mit.edu/~puzzle/2019/">here</a>. I wrote two puzzles, linked <a href="https://www.mit.edu/~puzzle/2019/puzzle/starbucks_lover.html">here</a> and <a href="https://www.mit.edu/~puzzle/2019/puzzle/date_and_thyme.html">here</a>.

    I've also written for the <a href="https://en.wikipedia.org/wiki/Microsoft_Puzzle_Hunt">Microsoft Puzzle Hunt</a> and the <a href="https://buildyourfuture.withgoogle.com/events/tech-challenge/">Google Tech Challenge</a>. I recently wrote <router-link to="/text-adventure">a text adventure puzzle</router-link> for a trivia event held at a large gaming convention.
	</p>
  <p>
    I'm also one of the hosts of the Seattle branch of <a href="https://puzzledpint.com/">Puzzled Pint</a>, a monthly puzzle event held in many cities around the world.
  </p>
	<!-- <p>
		Who knows, maybe even this site is a puzzle?
	</p> --> 
  </div>
</template>
<script>
export default {
  name: 'Puzzles'
}
</script>
<style scoped>
.puzzles {
  background-color: rgba(4, 99, 7, 0.8);
  backdrop-filter: blur(5px);
  width: 66%;
  border-radius: 0px 12px 12px 0px;
  box-shadow: 5px 5px 5px #424242;
  margin-left: -10px;
  margin-top: 10px;
}

a {
    color: #CD9B1D;
    font-weight: bold;
    text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .puzzles {
    width: auto;
    border-radius: 0;
    box-shadow: none;
  }
}
</style>